import * as Styled from './locationDataTableStyles';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { BLOCKS } from '@contentful/rich-text-types';
import ContentfulAssets from '../../hooks/useContentfulAssets';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './corousal.css';

const LocationCorousel = ({featuredSites})=>{
    
    const optionsImageStyle = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = ContentfulAssets(node.data.target.sys.id);
                if (asset) {
                return (
                    <Styled.Image1
                    src={asset.node.file.url}
                    alt={asset.node.title}
                    />
                );
                }
            }
        },
    };

    const optionsPDFStyle = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = ContentfulAssets(node.data.target.sys.id);
                if (asset) {
                return (
                    <Styled.PDFLink1
                    href={asset.node.file.url}
                    target='_self'
                    >
                        Learn More
                    </Styled.PDFLink1>
                );
                }
            }
        },
    };

    const cardData =[];
    for(let item=0;item<featuredSites?.length;item++){
    
        let element = <Carousel.Item  interval={2000}>
                    <Styled.CardContainer>
                        <Styled.Card1>
                            {documentToReactComponents(
                                JSON.parse(featuredSites[item]?.locationImage?.raw),
                                optionsImageStyle
                            )}
                            
                        <Styled.InfoContainer>
                            <Styled.HeaderH2Black>{featuredSites[item]?.sitename}</Styled.HeaderH2Black>
                            <Styled.HeaderH4>Type : <Styled.Content> Solar </Styled.Content></Styled.HeaderH4>
                            <Styled.HeaderH4>Location : <Styled.Content> {featuredSites[item]?.country} </Styled.Content></Styled.HeaderH4>
                            <Styled.HeaderH4>Gross Capacity (kWp) : <Styled.Content>{featuredSites[item]?.grossCapacityKWp}</Styled.Content></Styled.HeaderH4>
                            <Styled.HeaderH4>CO2 Offset Equivalency (metric tons)* : <Styled.Content>{featuredSites[item]?.co2OffsetEquivalency ? featuredSites[item]?.co2OffsetEquivalency : '76'}</Styled.Content></Styled.HeaderH4>
                            <br/>
                            {documentToReactComponents(
                                JSON.parse(featuredSites[item]?.locationPdf?.raw),
                                optionsPDFStyle
                            )}
                            
                        </Styled.InfoContainer>
                        </Styled.Card1>
                        </Styled.CardContainer>
                    </Carousel.Item>
    cardData.push(element);
    element='';
    }
    if (featuredSites.length === 0){
        return <></>
    }
    else{
    return <>
    <Styled.TitleContainer>
        <Styled.LineBehind><Styled.LineBehindSpan>Featured Sites</Styled.LineBehindSpan></Styled.LineBehind>
    </Styled.TitleContainer>
    <Carousel id='projects_corousal'>{cardData}</Carousel>
    </>
    }
    ;
}

export default LocationCorousel;