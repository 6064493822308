import styled from "styled-components";

export const MainDiv = styled.div`
  margin: 5%;
  margin-top: 5%;
  @media (max-width: 768px) {
    margin-top: 70px;
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const Th = styled.th`
  background-color: #00ae4f;
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  border: 2px solid;
`;

export const Td = styled.td`
  color: #007dc3;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  border: 2px solid #e7e7e7;
`;

export const Title = styled.h1`
  background-color: #fff;
  padding: 20px;
  font-size: 42px;
  font-weight: bold;
  background-color: #f5f5f5;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const LineBehind = styled.h2`
  width: 50%;
  text-align: center;

  border-bottom: 2px solid #007dc3;
  line-height: 0.1em;
  margin: 10px 0 20px;
  background-color: #fff;

  @media (max-width: 768px) {
    border-bottom: none;
    width: auto;
  }
`;

export const LineBehindSpan = styled.span`
  background-color: #fff;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;
  font-size: 44px;
  color: var(--font-color);
  @media (max-width: 1440px) {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 32px;
  }
  @media (max-width: 425px) {
    font-size: 24px;
    line-height: 24px;
    padding:0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

export const Icon = styled.img`
  width: 80%;
`;

export const BlueLineContainer = styled.div`
  display: none;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Blueline = styled.hr`
  color: #007dc3;
  border: 2px solid;
  opacity: 1;
  width: 100px;
  margin: 25px 0;
`;

export const AggregateDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 80px;
  gap: 100px;
  @media (max-width: 1024px) {
    gap: 50px;
  }
  @media (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
  }
`;

export const TextStyle = styled.div`
  color: #007dc3;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
`;

export const CardTextStyle = styled.div`
  //border: 4px solid #ff0000;
  border-radius: 10px;
  padding: 5px 35px;
  background: #fff;
  box-shadow: 0px 0px 9px 1px #dddddd;
  width: 375px;
  color: var(--font-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  @media (max-width: 1024px) {
    padding: 5px 10px;
  }
  @media (max-width: 768px) {
    width: 260px;

    padding: 10px 0px;
  }
`;

export const IconAggregateData = styled.img`
  width: 120px;
  margin: 10px;
  @media (max-width: 1440px) {
    width: 85px;
  }
`;

export const header3 = styled.span`
  font-size: 34px;
  font-weight: bold;
  color: var(--font-color);
  @media (max-width: 1440px) {
    font-size: 24px;
  }
`;

export const header6 = styled.span`
  font-size: 16px;
  color: var(--font-color);
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;

export const Image = styled.img`
  width: 100%;
  padding: 5px 0px;
`;

export const HeaderH2Black = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: var(--font-color);
  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const HeaderH4 = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 5px 0px;
  color: var(--font-color);
  @media (max-width: 1440px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    font-size: 12px;
    padding: 2px 0px;
  }
`;

export const Content = styled.span`
  font-weight: normal;
`;

export const Card = styled.div`
  width: 32%;
  box-shadow: 0px 0px 5px 1px #bbbbbb;
  padding: 20px;
`;

export const Card1 = styled.div`
  width: 65%;
  box-shadow: 0px 0px 5px 0px #bbbbbb;
  display: flex;
  background: #fff;
  height: 400px;
  border-radius: 15px;
  @media (max-width: 1440px) {
    height: 300px;
  }
  @media (max-width: 1024px) {
    height: 270px;
  }
  @media (max-width: 768px) {
    width: 99%;
    height: 250px;
    flex-direction: column;
    height: auto;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 50px 0px;
`;

export const PDFLink = styled.a`
  float: right;
  text-decoration: none;
  padding: 10px;
`;

export const PDFLink1 = styled.a`
  //float: right;
  text-decoration: none;
  background: #007dc3;
  padding: 10px;
  color: white;
  border-radius: 5px;
  margin-top: 10px;
  &:hover {
    color: white;
  }
  @media (max-width: 1024px) {
    margin-top: 0px;
    padding: 8px;
    font-size: 12px;
  }
`;

export const Image1 = styled.img`
  width: 55%;
  padding: 20px;
  @media (max-width: 768px) {
    width: 100%;
    max-height: 400px;
  }
  @media (max-width: 425px) {
    max-height: 270px;
  }
`;

export const PinIcon = styled.img`
  width: 50px;
  @media (max-width: 1440px) {
    width: 32px;
  }
`;

export const SearchBox = styled.input`
  float: right;
  width: 265px;
  padding: 10px;
  margin: 3px;
  @media (max-width: 768px) {
    width: 50%;
    padding: 5px;
  }
`;

export const InfoContainer = styled.div`
  padding: 25px 20px;
  @media (max-width: 1024px) {
    padding: 25px 10px;
  }
  @media (max-width: 768px) {
    padding: 10px 25px;
  }
`;
